import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { setChosenMonthlyFlex } from 'actions/renewalActions';
import useRenewalHandler from 'hooks/renewal/useRenewalHandler';
import useRenewalPriceCalculator from 'hooks/renewal/useRenewalPriceCalculator';
import trackEvent from 'services/AnalyticsService';
import Button from 'shared/components/Button/Button';
import TermsAndConditions from 'views/MyRac/Renewal/Contents/TermsAndConditions';
import styles from './FlexBasket.module.scss';

const Column = ({ isChosen, chosenMonthlyFlex, price, priceText, type }) => (
  <button
    className={isChosen ? `${styles.column} ${styles.chosen}` : styles.column}
    onClick={isChosen ? () => {} : () => {
      trackEvent(type === 'Monthly' ? 'MyRAC_Renewal_Monthly_Pressed' : 'MyRAC_Renewal_Annual_Pressed');
      dispatch(setChosenMonthlyFlex(!chosenMonthlyFlex));
    }}
    type="button"
  >
    <i className={isChosen ? `${styles.icon} ${styles.chosenIcon}` : styles.icon} />
    <div className={styles.price}>{price}</div>
    <div className={styles.priceText}>{priceText}</div>
    <div className={styles.button}>{type}</div>
  </button>
);

const FlexBasket = () => {
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const toggles = useSelector((state) => state.renewal.basket.toggles);
  const chosenMonthlyFlex = useSelector((state) => state.renewal.basket.chosenMonthlyFlex);

  const enableAutoRenew = pageModel?.autoRenewToggle?.isEnabled ? toggles.autoRenew : null;
  const enablePaperless = pageModel?.goPaperlessToggle?.isEnabled ? toggles.paperless : null;

  const { totalPrice, totalDiscountedPrice } = useRenewalPriceCalculator();
  // When user has chosen monthly flex then there is no option to opt in or out of auto renew policy anymore so we are sending null
  const { renewalHandler } = useRenewalHandler(chosenMonthlyFlex ? null : enableAutoRenew, enablePaperless, totalPrice, totalDiscountedPrice);

  useEffect(() => {
    trackEvent('MyRAC_Renewal_Change_To_Monthly_Displayed');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>Total</h4>
        <p>One price, two ways to pay</p>
      </div>
      <div className={styles.paymentSwitch}>
        <Column
          isChosen={chosenMonthlyFlex === false}
          chosenMonthlyFlex={chosenMonthlyFlex}
          price={`£${totalPrice?.toFixed(2)}`}
          priceText="12 month contract"
          type="Annual"
        />
        <Column
          isChosen={!!chosenMonthlyFlex}
          chosenMonthlyFlex={chosenMonthlyFlex}
          price={`£${pageModel.basketContent.monthlyHeadlinePriceNow.toFixed(2)}`}
          priceText="Cancel any time"
          type="Monthly"
        />
        <div className={styles.divider}>or</div>
      </div>
      <p className={styles.note}>
        You pay the same total amount whether you&apos;re paying monthly or annually.
      </p>
      <p className={styles.termsAndConditions}>
        <TermsAndConditions />
      </p>
      <Button
        onClickHandler={(event) => renewalHandler(event)}
        buttonText={`Pay ${chosenMonthlyFlex ? 'monthly' : 'annual'}`}
        large
      />
    </div>
  );
};

export default FlexBasket;
