/* eslint-disable import/no-cycle */
import { custInfoHasError, setCustomerInfo } from 'actions/customerInfoActions';
import { policyHasError, setPolicyInfo, setMotPlus } from 'actions/policyInfoActions';
import { setJwtToken } from 'actions/sessionActions';
import { setVehicle, vehicleHasError } from 'actions/vehiclesInfoActions';
import GigyaClient from 'auth/GigyaClient';
import * as APIConstants from 'constants/apiConstants';
import { COVER_STATES } from 'constants/vehicleConstants';
import { ResponseCode } from 'services/ContentService';
import { dispatch, getState } from 'store';
import * as commons from 'utils/commons';
import {
  anonymousPut, del, get, getEndpoint, patch, post, put, anonymousPost,
} from 'utils/httpHelpers';
import { loggerFunc } from 'utils/logger';
import trackEvent from 'services/AnalyticsService';
import { setDocumentsError, setDocuments } from '../actions/documentActions';
import { getMessage } from './MessageService';
import TileSets from '../utils/tileSets.enum';

const logger = loggerFunc('MyRACService');

const gigyaClient = new GigyaClient();

const getStateSession = () => {
  const state = getState();
  if (state && state.session) {
    return state.session;
  }
  return undefined;
};

const getCanonicalPayload = (method, correlationId, sessionId, payload) => (['GET', 'DELETE'].includes(method) ? null : JSON.stringify({
  correlationId,
  sessionId,
  payload,
}));

export const getVehicleMotHistory = async (registration, session) => {
  const pathParams = {
    registration,
  };

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${session.jwtToken}`);

  return get(APIConstants.API_MOT_HISTORY, headers, pathParams, false);
};

const anonymousSyncPost = (uri, queryParams, pathParams, payload) => {
  const session = getStateSession();
  const correlationId = commons.generateUID(8);
  const body = getCanonicalPayload('POST', correlationId, session ? session.sessionId : '', payload);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', getEndpoint(`${process.env.REACT_APP_WEB_EXP_HOST}${uri}`, queryParams, pathParams), false);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  try {
    xhr.send(body);
    if (xhr.status !== 200) {
      throw new Error(xhr);
    } else {
      return xhr.response;
    }
  } catch (err) { // instead of onerror
    logger.error('anonymousSyncPost', err);
    throw err;
  }
};

const anonymousSyncPatch = (uri, queryParams, pathParams, payload) => {
  const session = getStateSession();
  const correlationId = commons.generateUID(8);
  const body = getCanonicalPayload('PATCH', correlationId, session ? session.sessionId : '', payload);
  const xhr = new XMLHttpRequest();
  xhr.open('PATCH', getEndpoint(`${process.env.REACT_APP_WEB_EXP_HOST}${uri}`, queryParams, pathParams), false);
  xhr.setRequestHeader('ApiKey', process.env.REACT_APP_API_KEY);
  // Basic authentication opted for as JWT tokens can't be validated at this point
  xhr.setRequestHeader('Authorization', `Basic ${process.env.REACT_APP_USER_BASIC_AUTH}`);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  try {
    xhr.send(body);
    if (xhr.status !== 204) {
      throw new Error(xhr);
    } else {
      return xhr.response;
    }
  } catch (err) { // instead of onerror
    logger.error('anonymousSyncPatch', err);
    throw err;
  }
};

// Default customer login method
const getCustomerInfo = async (customerId) => {
  const pathParams = {
    customerId,
  };
  return get(APIConstants.API_CUSTOMER, null, pathParams, false);
};

// Fallback customer login method when 'clientId' from gigya is returned as null (we store this value in our state as 'customerId')
const getCustomerInfoByMemberNo = async (memberNo, uid) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', getEndpoint(`${process.env.REACT_APP_WEB_EXP_HOST}${APIConstants.API_CUSTOMER_FINDBYMEMBERSHIPID(memberNo, uid)}`), false);
  xhr.setRequestHeader('ApiKey', process.env.REACT_APP_API_KEY);
  // Basic authentication opted for as JWT tokens can't be validated at this point
  xhr.setRequestHeader('Authorization', `Basic ${process.env.REACT_APP_USER_BASIC_AUTH}`);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  try {
    xhr.send();
    if (xhr.status !== 200) {
      throw new Error(xhr);
    } else {
      return JSON.parse(xhr.response);
    }
  } catch (err) {
    logger.error('getCustomerInfoByMemberNo', err);
    throw err;
  }
};

// eslint-disable-next-line no-unused-vars,no-promise-executor-return
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Get and set a new JWT token
export const GetJWT = async (expirationTime) => new Promise((resolve, reject) => {
  gigyaClient.getJWT((jwtResponse) => {
    if (jwtResponse.status === 'OK') {
      if (jwtResponse.id_token !== null) {
        dispatch(setJwtToken(jwtResponse.id_token));
        resolve(jwtResponse.id_token);
      } else {
        this.handleError(new Error('id_token not found'), getMessage());
        reject(new Error('id_token not found'), getMessage());
      }
    } else {
      this.handleError(new Error(jwtResponse.status), getMessage());
      reject(new Error(jwtResponse.status), getMessage());
    }
  }, expirationTime);
});

const getCustomer = async (customerId) => new Promise((resolve, reject) => {
  // Check if the user is logged in and maybe only execute this when the user has logged in
  if (!getState().session.isLoggedIn) {
    reject(new Error('Not logged in'));
  }
  logger.debug('retrieving customer data');
  getCustomerInfo(customerId)
    .then((customer) => {
      logger.debug('storing customer data response', customer);
      dispatch(setCustomerInfo(customer.payload));
      resolve(customer.payload.Id);
    })
    .catch((error) => {
      logger.error('retrieving customer data failed due to', error);
      dispatch(custInfoHasError(true));
      reject(error);
    });
});

export const GetPolicyInfo = async (customerId) => {
  const hackRACLiteCoverType = (cover) => (
    cover.includes('RAC Lite') ? 'RAC Pay As You Go' : cover
  );
  const pathParams = {
    customerId,
  };

  try {
    logger.debug('retrieving policy data');
    const policy = (await get(
      APIConstants.API_POLICY_FINDBYCUSTOMERID,
      null,
      pathParams,
      false,
    )).payload;
    policy.CoverType.Title = hackRACLiteCoverType(policy.CoverType.Title);
    policy.CoverType.FullDescription = hackRACLiteCoverType(policy.CoverType.FullDescription);
    logger.debug('storing policy');
    dispatch(setPolicyInfo(policy));
    return policy.PolicyId;
  } catch (error) {
    logger.error('retrieving policy data failed due to', error);
    dispatch(policyHasError(true));
    throw error;
  }
};

/*
  Policy info needs to be refreshed else otherwise the customer can not update additional members
  in the same session because the Drive sequence id will be wrong. This is a convenience function for
  usage just in this service.
 */
export const GetLatestPolicyInfo = async () => {
  const {
    session: { customerId },
  } = getState();
  await GetPolicyInfo(customerId);
};

export const GetVehicleReminders = async (userId) => {
  const pathParams = {
    userId,
  };
  try {
    logger.debug('retrieving vehicles and reminders');
    const vehicle = (await get(APIConstants.API_VEHICLES_REMINDERS, null, pathParams)).payload;
    logger.debug('storing vehicles and reminders');
    dispatch(setVehicle(vehicle));
  } catch (error) {
    // we just suppress the error here because we don't want to stop the site
    logger.error('failed to retrieve vehicles data due to', error);
    dispatch(vehicleHasError(true));
  }
};

export const GetMotPlus = async (customerId, userId) => {
  try {
    const pathParams = {
      customerId,
      userId,
    };
    logger.debug('retrieving mot plus status');
    const response = await get(APIConstants.API_MOT_PLUS, null, pathParams);
    logger.debug('storing mot plus status');
    dispatch(setMotPlus(response?.payload));
  } catch (error) {
    logger.error('failed to retrieve mot plus data due to', error);
    dispatch(policyHasError(error));
  }
};

export const GetDocuments = async (customerId, policyId) => {
  try {
    const pathParams = {
      customerId,
      policyId,
    };
    logger.debug('retrieving customer documents');
    const documents = await get(APIConstants.API_DOCUMENTS_LIST, null, pathParams);
    logger.debug('storing customer documents');
    dispatch(setDocuments(documents));
  } catch (error) {
    // we just suppress the error here because we don't want to stop the site
    logger.error('failed to retrieve the documents data due to', error);
    dispatch(setDocumentsError(error));
  }
};

export const GetLatestPolicyDocuments = async () => {
  const {
    session: { customerId },
    policy: { policyInfo },
  } = getState();

  await GetDocuments(customerId, policyInfo.PolicyId);
};

export const GetCustomerData = async () => {
  const {
    session: {
      customerId, memberNo, uid,
    },
  } = getState();

  // If 'customerId' is null at this point then the 'memberNo' and 'uid' will be used to action a separate API call in getCustomerInfoByMemberNo()
  return getCustomer(customerId, memberNo, uid).then((id) => Promise.all(
    [
      new Promise((resolve, reject) => {
        GetPolicyInfo(id).then((policyId) => {
          resolve(
            GetDocuments(id, policyId),
            GetMotPlus(customerId, uid),
          );
        }).catch((error) => reject(error));
      }),
      GetVehicleReminders(uid)],
  ));
};

export const GetCustomerDataByMemberNo = async (memberNo, uid) => new Promise((resolve, reject) => {
  // where 'customerId' is null use alternative login method passing 'memberNo' and 'uid'
  logger.debug('retrieving customer data using memberNo');
  getCustomerInfoByMemberNo(memberNo, uid)
    .then((customer) => {
      logger.debug('storing customer data response', customer);
      dispatch(setCustomerInfo(customer.payload));
      resolve(customer.payload);
      return customer.payload;
    })
    .catch((error) => {
      logger.error('retrieving customer data failed due to', error);
      dispatch(custInfoHasError(true));
      reject(error);
      return error;
    });
});

export const UpdateEmailAddress = async (emailAddress, membershipNo) => {
  const pathParams = {
    membershipNo,
  };

  const payload = {
    Email: emailAddress,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await put(APIConstants.API_USER_EMAIL, null, pathParams, payload);
};

export const UpdateRegistrationEmailAddress = (emailAddress, membershipNumber) => {
  const pathParams = {
    membershipNumber,
  };

  const payload = {
    Email: emailAddress,
  };
  return anonymousSyncPatch(APIConstants.API_CUSTOMER_CONTACT_EMAIL, null, pathParams, payload);
};

export const UpdateCustomerAddress = async (address, customerId) => {
  const pathParams = {
    customerId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await patch(APIConstants.API_CUSTOMER_CONTACT_ADDRESS, null, pathParams, address);
};

export const GetAddressById = async (addressId) => {
  const pathParams = {
    addressId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await get(APIConstants.API_CUSTOMER_ADDRESS_RETRIEVE, null, pathParams);
};

export const GetAddressesByPostCode = async (postCode) => {
  const pathParams = {
    postCode,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await get(APIConstants.API_CUSTOMER_ADDRESS_SEARCH, null, pathParams);
};

export const UpdateCommunicationsPref = async (customerId, preferences) => {
  const pathParams = {
    customerId,
  };
  // eslint-disable-next-line no-return-await
  return await patch(APIConstants.API_CUSTOMER_COMMS, null, pathParams, preferences);
};

export const UpdateDocumentPrefs = async (customerId, payload) => {
  const pathParams = {
    customerId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await patch(APIConstants.API_CUSTOMER_CONTACT_DOCUMENT_PREFS, null, pathParams, payload);
};

export const UpdateDateOfBirth = async (customerId, payload) => {
  const pathParams = {
    customerId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await patch(APIConstants.API_CUSTOMER_DOB, null, pathParams, payload);
};

// eslint-disable-next-line no-return-await
export const UpdateAutorenewal = async (policyId, payload) => await patch(APIConstants.API_AUTORENEWAL_PREFS, null, null, payload);

export const GetDocument = async (customerId, docType, documentId) => {
  const pathParams = {
    customerId,
    docType,
    documentId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await get(APIConstants.API_DOCUMENT, null, pathParams);
};

export const UpdateCustomerPhoneNumber = async (customerId, payload) => {
  const pathParams = {
    customerId,
  };
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await patch(APIConstants.API_CUSTOMER_CONTACT_PHONE, null, pathParams, payload);
};

// eslint-disable-next-line
export const UpdatePassword = async (payload) => {
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await put(APIConstants.API_PASSWORD_UPDATE, null, null, payload);
};

// eslint-disable-next-line
export const ResetPassword = async (payload) => {
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await anonymousPut(APIConstants.API_PASSWORD_RESET, null, null, payload);
};

export const ValidateMember = (memberNo, payload) => {
  const pathParams = {
    membershipNo: memberNo,
  };
  return anonymousSyncPost(APIConstants.API_USER_VALIDATE, null, pathParams, payload);
};

export const ValidateAndGetMagicLink = (payload) => anonymousPost(APIConstants.API_USER_MAGICLINK_CREATE, null, null, payload);
export const IsEmailAvailable = (payload) => anonymousSyncPost(APIConstants.API_EMAIL_AVAILABLE, null, null, payload);

export const UpdateAdditionalMember = async (customerId, policyId, payload) => {
  const pathParams = {
    policyId,
  };

  const queryParam = {
    customerId,
  };

  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await post(APIConstants.API_POLICY_UPDATEADDITIONALMEMBERS, queryParam, pathParams, payload);
};

// eslint-disable-next-line
export const FindVehicle = async (payload) => {
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await post(APIConstants.API_FIND_VEHICLES, null, null, payload);
};

export const SaveVehicle = async (userId, payload) => {
  const pathParams = {
    userId,
  };

  trackEvent('myrac.vehicledetails_addvehicle_submit');
  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await post(APIConstants.API_SAVE_VEHICLE, null, pathParams, payload);
};

export const GetTescoLoginJID = async (customerId, membershipNo) => {
  const pathParams = {
    customerId,
  };

  const queryParam = {
    membershipNo,
  };

  // TODO: Return the promise and handle it that way instead of waiting for it here.
  // eslint-disable-next-line no-return-await
  return await get(APIConstants.API_CUSTOMER_JID_TESCO_LOGIN, queryParam, pathParams);
};

export const UpdateVehicleReminders = async (userId, registration, payload) => {
  const pathParams = {
    userId,
    registration,
  };
  trackEvent('myrac.vehicledetails_reminders_saved');
  const actionResponse = await put(APIConstants.API_UPDATE_VEHICLES_REMINDERS, null, pathParams, payload);
  if (actionResponse.responseCode === ResponseCode.SUCCESS) {
    // run the call for refreshing reminders in the background - we don't need it to
    // update immediately.
    GetVehicleReminders(userId);
  }
  return actionResponse;
};

export const GetMotInfo = async (registration) => {
  const pathParams = {
    registration,
  };
  return get(APIConstants.API_MOT_INFO, null, pathParams);
};

export const DeleteVehicle = async (userId, registration) => {
  const pathParams = {
    userId,
    registration,
  };

  trackEvent('myrac.vehicledetails_deletevehicle_submit');
  const actionResponse = await del(APIConstants.API_VEHICLE_BY_REGISTRATION, null, pathParams, null);
  if (actionResponse.responseCode === ResponseCode.SUCCESS) {
    GetLatestPolicyInfo();
  }
  return actionResponse;
};

export const UpdateVehicle = async (userId, registration, payload, coverState) => {
  const pathParams = {
    userId,
    registration,
  };
  switch (coverState) {
    case COVER_STATES.IS_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_HYBRID_COVERED_BY_BREAKDOWN:
    case COVER_STATES.IS_PAY_AS_YOU_GO_COVERED:
      trackEvent('myrac.vehicledetails_editsubmit_VBM');
      break;
    case COVER_STATES.IS_COVERED_FOR_REGISTERED_USERS:
    case COVER_STATES.IS_HYBRID_COVERED_FOR_REGISTERED_USERS:
    case COVER_STATES.IS_MEMBERSHIP_ONLY:
      trackEvent('myrac.vehicledetails_editsubmit_PBM');
      break;
    default:
      trackEvent('myrac.vehicledetails_editsubmit_Reminder');
      break;
  }
  const actionResponse = await put(APIConstants.API_VEHICLE_BY_REGISTRATION, null, pathParams, payload);
  if (actionResponse.responseCode === ResponseCode.SUCCESS) {
    GetLatestPolicyInfo();
  }
  return actionResponse;
};

export const GetLayoutGrid = async (customerId, membershipId, tileSet, mobile) => {
  const pathParams = {
    customerId,
    membershipId,
  };

  if (mobile) {
    switch (tileSet) {
      case TileSets.membershipOverview: return get(APIConstants.API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT_MOBILE, null, pathParams, false);
      default: return get(APIConstants.API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT_MOBILE, null, pathParams, false);
    }
  }

  switch (tileSet) {
    case TileSets.membershipOverview: return get(APIConstants.API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT, null, pathParams, false);
    case TileSets.documentsPageAds: return get(APIConstants.API_DOCUMENT_GRID_LAYOUT, null, pathParams, false);
    default: return get(APIConstants.API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT, null, pathParams, false);
  }
};

export const GetStaticContent = async (contentTypeKey) => {
  const pathParams = {
    contentTypeKey,
  };
  logger.debug('retrieving static content ');

  return get(APIConstants.API_STATIC_CONTENT, null, pathParams, false);
};

export const GetProductUpgradeData = async (customerId, productName) => {
  const pathParams = {
    customerId,
    productName,
  };
  logger.debug('retrieving product upgrade data for the product: ', productName);

  return get(APIConstants.API_TACTICAL_UPGRADE_DATA, null, pathParams, false);
};

export const GetPolicyStatus = async () => {
  logger.debug('retrieving policy status');

  return get(APIConstants.API_POLICY_STATUS, null, null, false);
};

export const GetPolicyDetails = async () => {
  logger.debug('retrieving policy details');

  return get(APIConstants.API_POLICY_DETAILS, null, null, false);
};

export const GetPolicyRenewalDetails = async () => {
  logger.debug('retrieving policy renewal details');

  return get(APIConstants.API_POLICY_RENEWAL_DETAILS, null, null, false);
};

export const GetProductUpgradeList = async () => {
  logger.debug('retrieving product upgrade list');

  return get(APIConstants.API_PRODUCT_UPGRADES, null, null, false);
};

export const GetProductsIncludedInPolicy = async () => {
  logger.debug('retrieving products included in policy');

  return get(APIConstants.API_PRODUCTS_INCLUDED, null, null, false);
};

export const SaveProductUpgradeProduct = async (upgradeProductPayload) => post(APIConstants.API_PRODUCT_UPGRADE_CONFIRMATION, null, null, upgradeProductPayload);

export const GetPaymentId = async (customerId, productName, membershipNumber, price, vehicleRegistration, extraPersonTitle, extraPersonFirstname, extraPersonSurname, extraPersonDateOfBirth) => {
  logger.debug('retrieving payment ID for Global Pay Iframe.');

  const payload = {
    customerId,
    productName,
    membershipNumber,
    price,
    vehicleRegistration,
    extraPersonTitle,
    extraPersonFirstname,
    extraPersonSurname,
    extraPersonDateOfBirth,
  };

  return post(APIConstants.API_PRODUCT_CREATE_PAYMENT_ID, null, null, payload);
};

export const UpdateProductUpgradePayment = async (paymentId, customerId, productName, membershipNumber, price) => {
  logger.debug('updating payment in payment database.');

  // The 'vehicleRegistration' value is optional and null by default unless required to be passed from ProductUpgradeJourney
  const payload = {
    customerId,
    productName,
    membershipNumber,
    price,
  };

  return put(APIConstants.API_PRODUCT_PAYMENT_STATUS(paymentId), null, null, payload);
};

export const GetRenewalData = async () => {
  const { customerId } = getState().session;

  const pathParams = {
    customerId,
  };
  logger.debug('retrieving renewal membership data');

  return get(APIConstants.API_RENEWAL_DATA, null, pathParams, false);
};

export const GetRenewalPaymentIdAndLockToken = async () => {
  logger.debug('retrieving payment id for ssp renewal');

  const { uid, sessionId, isTescoLogin } = getState().session;

  const payload = {
    ...getState().renewal.paymentPayload,
    isTescoLogin,
    LockInformation: {
      UserId: uid,
      SessionId: sessionId,
    },
  };

  return post(APIConstants.API_RENEWAL_CREATE_PAYMENT_ID, null, null, payload);
};

export const SetRenewalPaymentStatus = async (Price, PaymentId, LockToken) => {
  logger.debug('updating payment status in payment database');

  const payload = {
    Price,
    PaymentId,
    LockToken,
  };

  return put(APIConstants.API_RENEWAL_PAYMENT_STATUS, null, null, payload);
};

export const SetRenewalDirectDebitStatus = async (CustomerId, MembershipNumber, PolicyNumber, Price, RiskVersion, LockToken, AccountHolder, AccountNumber, Sortcode) => {
  logger.debug('updating direct debit status in database');

  const payload = {
    CustomerId,
    MembershipNumber,
    PolicyNumber,
    Price,
    RiskVersion,
    LockToken,
    AccountHolder,
    AccountNumber,
    Sortcode,
  };

  const { MemberChoseSwitchToMonthlyFlex } = getState().renewal.paymentPayload;

  // Monthly flex must use endpoint that will only validate direct debit data
  const url = MemberChoseSwitchToMonthlyFlex
    ? APIConstants.API_RENEWAL_DIRECT_VALIDATE_ONLY
    : APIConstants.API_RENEWAL_DIRECT_DEBIT_STATUS;

  return put(url, null, null, payload);
};

export const ReleaseLockToken = async (lockToken) => {
  logger.debug(`releasing lock token ${lockToken}`);
  const payload = lockToken;
  return post(APIConstants.API_RENEWAL_RELEASE_LOCK, null, null, payload);
};

export const GetRenewalPopup = async (customerId) => {
  const pathParams = {
    customerId,
  };
  logger.debug('retrieving renewal popup eligibility and data');

  return get(APIConstants.API_RENEWAL_POPUP, null, pathParams, false);
};

export const GetRenewalEligibility = async (customerId) => {
  const pathParams = {
    customerId,
  };
  logger.debug('retrieving renewal eligibility information');

  return get(APIConstants.API_RENEWAL_ELIGIBILITY, null, pathParams, false);
};

export const GetPaymentStatus = async (paymentId) => {
  logger.debug('retrieving current payment status: ', paymentId);

  return get(APIConstants.API_PRODUCT_PAYMENT_STATUS(paymentId), null, {}, false);
};

export const GetSMRContent = async () => {
  logger.debug('retrieving SMR content ');

  return get(APIConstants.API_SMR_CONTENT, null, {}, false);
};
